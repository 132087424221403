// $('.clockpicker').clockpicker();


$(window).load(function(){
  
  iti = window.intlTelInput(document.querySelector("#phone"),{
    initialCountry: "auto",
    geoIpLookup: function(success, failure) {
      $.get("https://ipinfo.io", function() {}, "jsonp").always(function(resp) {
        var countryCode = (resp && resp.country) ? resp.country : "";
        success(countryCode);
      });
    }
  });


  $('a#CHRISTMAS').on('click',function(){
    console.log('clickled')
    // $(".menu-tabs li:not(.christmas_menu_tab)").add(".tab-content #lunch, .tab-content #dinner, .tab-content #pudding, .tab_content #new_year").hide();
    // $(".tab-content #christmas").addClass('active');
    // $(".menu-wrapper").scrollView();
    // document.getElementsByClassName("menu-wrapper").scrollIntoView()
    // $("body").animate({
    //     scrollTop : $(".menu-wrapper").offset().top
    // }, 1400);
    document.getElementById('menu').scrollIntoView();
    // $("body").animate({
    //     scrollTop : $(".menu-wrapper")
    // }, 400);
    console.log('clickled end')
  })
})

$("#reservation-form").submit(function(e){
  $('#form-loader').removeClass('hidden-custom');
  $('#reservation-btn').addClass('hidden-custom');
  var countryData = iti.getSelectedCountryData();
  console.log(countryData)
  // var data = $(this).serializeArray();
  // data.push({name: 'TESTALANI', value: 'TEST VALUEE'});
  axios.post('/reservation', $(this).serialize() + "&Phone_Country=" + countryData.name + "&Phone_Dial_Code=+" + countryData.dialCode)
    .then(function (response) {
      // console.log(response);
      $('#form-loader').addClass('hidden-custom');
      $('#reservation-btn').removeClass('hidden-custom');
      document.getElementById('reservation-message').style.display='block';
      return false;
      // setTimeout(function () {
      //     document.getElementById('reservation-message').style.display='none';
      // }, 8000);
    })
    .catch(function (error) {
      console.log(error);
    });
  return false;
});
